import React from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchStoryProjects from '../3_hooks/useFetchStoryProjects';

const ViewProjects = () => {
	const navigate = useNavigate();
	const { data, error } = useFetchStoryProjects(`/api/projects/`);

	const handleEdit = (projectId) => {
		navigate(`/edit-project/${projectId}`);
	};

	const handleAddNew = () => {
		navigate('/add-project');
	};

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('crudToken');
    // Redirect to login page
    navigate('/LoginPage');
  };



	return (
		<div style={styles.container}>
			<p>Projects</p>
			<div style={styles.actions}>
				<button onClick={handleAddNew} style={styles.buttonPrimary}>
					Add New Story
				</button>
				<button onClick={handleLogout} style={styles.buttonSecondary}>
					Log Out
				</button>
			</div>
			<table style={styles.table}>
				<thead>
					<tr>
						<th style={styles.th}>Project ID</th>
						<th style={styles.th}>Title</th>
						<th style={styles.th}>Description</th>
						<th style={styles.th}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{data.map((project) => (
						<tr key={project.ProjectID}>
							<td style={styles.td}>{project.ProjectID}</td>
							<td style={styles.td}>{project.Title}</td>
							<td style={styles.td}>{project.Description}</td>
							<td style={styles.td}>
								<button onClick={() => handleEdit(project.ProjectID)} style={styles.buttonWarning}>
									Edit
								</button>
								<button style={styles.buttonDanger}>Delete</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
	// Define styles as a const object
	const styles = {
		container: {
			width: '80%',
			margin: '0 auto',
			padding: '20px',
			display: 'flex',
			alignContent: 'center',
			flexDirection: 'column',
		},
		actions: {
			display: 'flex',
			marginBottom: '2vh',
		},
		buttonPrimary: {
			backgroundColor: '#007bff',
			color: 'white',
			padding: '10px 15px',
			border: 'none',
			borderRadius: '5px',
			cursor: 'pointer',
			marginRight: '10px',
		},
		buttonSecondary: {
			backgroundColor: '#6c757d',
			color: 'white',
			padding: '10px 15px',
			border: 'none',
			borderRadius: '5px',
			cursor: 'pointer',
		},
		buttonWarning: {
			backgroundColor: '#ffc107',
			color: 'white',
			padding: '8px 12px',
			border: 'none',
			borderRadius: '5px',
			cursor: 'pointer',
			marginRight: '10px',
		},
		buttonDanger: {
			backgroundColor: '#dc3545',
			color: 'white',
			padding: '8px 12px',
			border: 'none',
			borderRadius: '5px',
			cursor: 'pointer',
		},
		table: {
			width: '100%',
			borderCollapse: 'collapse',
			marginBottom: '20px',
		},
		th: {
			textAlign: 'center ',
		},
		td: {
			padding: '10px',
			borderBottom: '1px solid #dee2e6',
		},
	};
export default ViewProjects;
