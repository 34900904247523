import React from 'react';
import TravelingMedia from '../../5_assets/myImages/Traveling.webp';

function GlobalExposure() {
	const styles = {
		lifeStory: {
			overflow: 'hidden',
			padding: '5vh 0px',
			width: '80vw',
			height: '100%',
			margin: '0 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		firstSpark: {
			fontSize: 'var(--heading-5-xl)',
			marginBottom: '1vh',
			paddingBottom: '1.5vh',
		},
		numbering: {
			color: 'var(--font-hover-color)',
		},
		lifeStoryInnerContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row-reverse',
			position: 'relative',
			width: '100%',
			justifyContent: 'space-around',
		},
		lifeStoryContent: {
			fontSize: 'var(--body-mm)',
			fontWeight: 'normal',
			width: '65%',
		},
		lifeStoryPersonnalImage: {
			margin: 'auto',
			borderRadius: '10px',
			maxWidth: '30%',
			maxHeight: '100%',
		},
	};

	return (
		<div className="life-story aos-init aos-animate" style={styles.lifeStory}>
			<p className="firstSpark" style={styles.firstSpark}>
				<span className="numbering" style={styles.numbering}>
					3.
				</span>
				Global Exposure
			</p>
			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<img className="lifeStoryPersonnalImage " style={styles.lifeStoryPersonnalImage} src={TravelingMedia} title="g=10m/s^2" alt="Jumping with DoBull around my head " />
				<div className="lifeStory-content" style={styles.lifeStoryContent}>
					<p className="lifeStory-containerText">Though I excelled academically, ranking #5 in my class, and formed lasting relationships during my time at Delta University, I always felt a yearning for broader horizons and new challenges.</p>
					<p className="lifeStory-containerText">
						This sentiment was realized on July 2nd, 2020, when I received an acceptance notification from the prestigious{' '}
						<a className="animated-link" href="https://stipendiumhungaricum.hu/" target="_blank" rel="noreferrer">
							Stipendium Hungaricum
						</a>{' '}
						scholarship program to join{' '}
						<a className="animated-link" href="https://uni-eszterhazy.hu/" target="_blank" rel="noreferrer">
							Eszterházy Károly Catholic University
						</a>
						. This opportunity marked a significant turning point in my academic and personal journey.
					</p>
					<p className="lifeStory-containerText">Stepping into this new chapter at EKKE was both enlightening and challenging. Faced with an unfamiliar culture, I quickly adapted, emphasizing mutual respect and understanding. This experience reinforced the importance of adaptability and cultural sensitivity in any global setting.</p>
				</div>
			</div>
		</div>
	);
}

export default GlobalExposure;
