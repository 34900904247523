import React, { useEffect } from 'react';
import SEO from '../4_utils/SEO.jsx';
import { changePageTitle } from '../4_utils/functions.jsx';
import IntroSection from '../2_components/IntroSection.jsx';
import { LifeStory } from './LifeStory.jsx';
import { Top3Projects } from '../2_components/FeaturedProjects.jsx';
import { ContactForm } from '../2_components/ContactMe.jsx';

export function Body() {
	useEffect(() => {
		changePageTitle(`Ahmed Mahfouz`);
	});

	return (
		<>
			<SEO Title="Pixels and Programming: The Portfolio of Ahmed Mahfouz" description="Greetings and salutations! I'm Ahmed Mahfouz, and this is where the magic of code comes to life. Step into a realm where imagination meets technology as I guide you through my projects, delve into my academic journey, and share the pivotal moments that shaped me into the programmer I am today." name="Portfolio of Ahmed Mahfouz" type="Portfolio web site" />

			<IntroSection />

			<hr className="page-break"></hr>
			<LifeStory />

			<div className="center-link">
				<a href="/Resume.pdf" className="animated-link" target="_blank">
					My Resume
				</a>
			</div>
			<hr className="page-break"></hr>
			<Top3Projects />

			<hr className="page-break"></hr>
			<ContactForm />
		</>
	);
}
