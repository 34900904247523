import React from 'react';
import { Link } from 'react-router-dom';
import notfound404 from '../5_assets/404.jpg';

const Error404 = () => {
	const styles = {
		error404Container: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			width: '100%',
			margin: 'auto',
			textAlign: 'center',
		},
		image: {
			marginBottom: '3vh',
			borderRadius: '10px',
			minWidth: '20%',
			width: 'auto',
			height: 'auto',
			maxWidth: '50vw',
			maxHeight: '100%',
		},
		paragraph: {
			fontSize: 'var(--body-mm)',
			whiteSpace: 'pre-line',
		},
		link: {
			color: 'var(--font-hover-color)',
			textDecoration: 'none',
		},
	};

	return (
		<div style={styles.error404Container}>
			<img src={notfound404} alt="404" style={styles.image} />
			<p style={styles.paragraph}>
				I'm{' '}
				<span data-toggle="tooltip" data-placement="top" title="aren't we all">
					fragile
				</span>{' '}
				and it seems that I broke. Go to{' '}
				<Link to="/home" style={styles.link} className="animated-link">
					home page
				</Link>
			</p>
		</div>
	);
};

export default Error404;
