import React from 'react';
import styles from '../../5_assets/styles/ThesisTimeline.module.css';

function Timeline() {
	return (
		<details className={styles.thesisTimeLine}>
			<summary style={{ fontSize: '2em', fontWeight: 'bold' }}>Timeline</summary>

			<p>The first step of developing the game was choosing a programming language that would be helpful in both showing graphics on screen and implement an AI algorithm. The only option that was convenient enough was python as:</p>
			<ul>
				<li>The most famous programming language with AI libraries that have a good documentation.</li>
				<li>It is OOP, which means I can have a class for each component of the game easily to make an instance for the AI to train from.</li>
				<li>A library to draw graphic on screen while it won't need heavy CPU usage that won't throttle the process of AI learning </li>
			</ul>
			<p>
				Going for a library for the game was the first step as it would define the characteristics. I went for <strong>PyGame</strong> because it was nearly the only one that is good enough with documentation to start with, and quit enough, the main focus isn't about making a game that will have that much of physics in it and 3d animation. For now, the imagined picture of the game is a rectangle as a screen that will have two main component of the game, a wave that is on one side of the screen vertically and one on the other side with the only difference is 350px (the starting amplitude is 50px and the screen width is 400px) and a ball that the <strong>only purpose for it is to survive as much as it can, without hitting any of the sides of the wave</strong>.
			</p>
			<p>I'm minimalist when it comes to developing things, like there wouldn't be splash screen all over, and hard controls, not even hard rules, and it will be the approach I'm following with the game, to break it into steps</p>
			<ol>
				<li>The accent colour of the game will be black as a background</li>
				<li>White will be used to show elements</li>
				<li>
					Score counter on top
					<ol>
						<li>AI mode: show generation and genome number</li>
						<li>AI mode: show total runtime</li>
						<li>AI mode: show the vision of the ball</li>
					</ol>
				</li>
			</ol>
			<p>During the writing here, I will raise some questions that might come to your mind while working on some parts (as they might have came to me too) and will try to answer them at the end of every section in the chapter.</p>
		</details>
	);
}

export default Timeline;
