import React from 'react';
import YoungMeMedia from '../../5_assets/myImages/YoungMe.webp';

function FromSpark() {
	const styles = {
		lifeStory: {
			overflow: 'hidden',
			padding: '5vh 0px',
			width: '80%',
			height: '100%',
			margin: '0 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		firstSpark: {
			fontSize: 'var(--heading-5-xl)',
			marginBottom: '1vh',
			paddingBottom: '1.5vh',
		},
		numbering: {
			color: 'var(--font-hover-color)',
		},
		lifeStoryInnerContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'right',
			position: 'relative',
			width: '100%',
			justifyContent: 'space-around',
		},
		lifeStoryContent: {
			fontSize: 'var(--body-mm)',
			fontWeight: 'normal',
			width: '65%',
		},
		lifeStoryPersonnalImage: {
			margin: 'auto',
			borderRadius: '10px',
			maxWidth: '30%',
			maxHeight: '100%',
		},
	};

	return (
		<div className="life-story" style={styles.lifeStory} >
			<p className="firstSpark" style={styles.firstSpark}>
				<span className="numbering" style={styles.numbering}>
					1.
				</span>
				From Spark
			</p>
			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<p className="lifeStory-content" style={styles.lifeStoryContent}>
					From a tender age, my world was colored with curiosities. Whether it was the simple act of connecting batteries or the daring thought of exploring wall outlets, my inquisitiveness knew no bounds. Thankfully, with guidance and time, I channeled this energy into more constructive avenues.
				</p>
				<img className="lifeStoryPersonnalImage" src={YoungMeMedia} alt="Reading a story to my little sister" title="I couldn't read then" style={styles.lifeStoryPersonnalImage} />
			</div>
		</div>
	);
}

export default FromSpark;
