import React from 'react';
import AIModelMedia from '../../5_assets/Thesis/AI(12.28).webm';

const AISolving = () => {
	return (
		<div id="gameAISolving" style={styles.container}>
			<p style={styles.header}>Not enough?</p>
			<div style={styles.thesisInnerContent}>
				<video style={{ ...styles.thesisMedia, ...styles.aiPlay }} preload="auto" autoPlay muted loop>
					<source src={AIModelMedia} type="video/webm" />
				</video>
				<div style={styles.thesisContent}>
					<p style={styles.thesisInnerHeader}>How about adding AI to beat the game?</p>
					<p>I used the N.E.A.T algorithm to train AI that can beat the game.</p>
					<ul>
						<li>The yellow lines are the vision for the ball.</li>
						<li>A grey box shows the collision area.</li>
						<li>Particles behind the ball are removed to save CPU resources.</li>
						<li>The highest score reached over 30 minutes of gameplay.</li>
					</ul>
					<p style={styles.youTubeNote}>
						View the full training session on{' '}
						<a style={styles.inlineLink} href="https://www.youtube.com/watch?v=ZYRZjhS5VgA">
							YouTube
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	);
};

const styles = {
	container: {
		height: '100vh',
		position: 'relative',
		zIndex: 9,
	},
	header: {
		fontSize: 'var(--heading-6-xl)',
		paddingTop: '3vh',
		paddingBottom: '3vh',
		fontWeight: 400,
		textAlign: 'center',
	},
	thesisInnerContent: {
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '85vw',
		position: 'relative',
		display: 'flex',
		gap: '3vw',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	thesisMedia: {
		margin: 'auto',
		marginLeft: '2vw',
		borderRadius: '6px',
		width: 'auto',
		height: 'auto',
		border: '2px solid #5d5d5d',
		animation: 'breathing 2s linear alternate-reverse infinite',
	},
	aiPlay: {
		maxWidth: '19%',
		maxHeight: '100%',
	},
	thesisContent: {
		fontSize: 'var(--body-mm)',
		whiteSpace: 'pre-line',
		marginRight: 'auto',
	},
	thesisInnerHeader: {
		fontSize: 'var(--heading-3-xl)',
	},
	youTubeNote: {
		fontSize: 'var(--body-mm)',
	},
	inlineLink: {
		color: 'var(--Overall-font-color)',
		textShadow: '1px 1px 3px #fff',
	},
};

export default AISolving;
