import { useState } from 'react';

const useHandleProject = () => {
	const [error, setError] = useState(null);

	const handleProject = async (url, method, project) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
				method,
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(project),
			});

			if (!response.ok) {
        console.log(response)
				throw new Error('Network response was not ok');
			}

			const result = await response.json();
			return result;
		} catch (error) {
			console.error(`Error ${method === 'PUT' ? 'updating' : 'adding'} project:`, error);
			setError(error);
		}
	};

	return { handleProject, error };
};

export default useHandleProject;
