import React from 'react';
import RobotTestingMedia from '../../5_assets/myImages/RobotTesting.webp';

function RoboticInnovation() {
	return (
		<div className="life-story" style={styles.lifeStory} >
			<p className="firstSpark" style={styles.firstSpark}>
				<span className="numbering" style={styles.numbering}>
					2.
				</span>
				Robotic Innovations
			</p>

			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<div className="lifeStory-content" style={styles.lifeStoryContent}>
					<p className="lifeStory-containerText">In my current capacity as an AI developer , I work on the development and enhancement of an advanced forest robot. This machine is designed to assist farmers by autonomously transporting goods, optimising their daily operations.</p>
					<div className="lifeStory-containerText">
						Key Project Highlights:
						<ul className="lifeStory-RoboticKeyProject">
							<li>
								<strong>Tech Stack</strong>: My work heavily depends on Python and Javascript, combined with tools like Balenacloud for seamless fleet management.
							</li>
							<li>
								<strong>Transitioning to a Mobile-Based Control Interface</strong>: I've developed a mobile-based interface, introducing a new era of user interaction. This interface is a ground-up creation, designed to be the standard mode of operation. It improves the robot's adaptability and the user experience. Additionally, this development involved reverse-engineering the existing codebase, ensuring seamless integration with the new mobile operation system.
							</li>
							<li>
								<strong>Robot communication</strong>: A key aspect of my work involves developing advanced communication systems between robots. Utilising ZMQ, I engineered a socket communication system that operates at a low level, optimising CPU usage for greater efficiency. This system enables rapid data exchange between robots, with transmission speeds averaging 0.0043 seconds.
							</li>
						</ul>
					</div>
				</div>
				<img style={styles.lifeStoryPersonnalImage} src={RobotTestingMedia} title="maybe it is spring" alt="Testing new release of the mobile controller on 5Earl robot" />
			</div>

		</div>
	);
}

const styles = {
	lifeStory: {
		overflow: 'hidden',
		padding: '5vh 0px',
		width: '80vw',
		height: '100%',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	firstSpark: {
		fontSize: 'var(--heading-5-xl)',
		marginBottom: '1vh',
		paddingBottom: '1.5vh',
	},
	numbering: {
		color: 'var(--font-hover-color)',
	},
	lifeStoryInnerContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'right',
		position: 'relative',
		width: '100%',
		justifyContent: 'space-around',
	},
	lifeStoryContent: {
		fontSize: 'var(--body-mm)',
		fontWeight: 'normal',
		width: '65%',
	},
	lifeStoryPersonnalImage: {
		margin: 'auto',
		borderRadius: '10px',
		maxWidth: '30%',
		maxHeight: '100%',
	},
};

export default RoboticInnovation;
