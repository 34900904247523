import React from 'react';
import { Link } from 'react-router-dom';
import markdownLog from '../../5_assets/Thesis/markdown log.png';

const BehindTheScenes = () => {
	return (
		<div id="gameBehindTheScenes" style={styles.container}>
			<p style={styles.header}>Curious for more</p>
			<div style={styles.thesisInnerContent}>
				<img style={styles.markDownDiary} src={markdownLog} alt="Markdown Diary" />
				<div style={styles.thesisContent}>
					<p>I documented the project progress in a diary while developing the game, which helped in writing my thesis later.</p>
					<p>
						You can view the diary I wrote during the development{' '}
						<Link to="/thesistimeline" style={styles.inlineLink}>
							here
						</Link>
						.
					</p>
				</div>
			</div>
		</div>
	);
};

const styles = {
	container: {
		height: '100vh',
		position: 'relative',
		zIndex: 9,
	},
	header: {
		fontSize: 'var(--heading-6-xl)',
		paddingTop: '3vh',
		paddingBottom: '3vh',
		fontWeight: 400,
		textAlign: 'center',
	},
	thesisInnerContent: {
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '85vw',
		position: 'relative',
		display: 'flex',
		gap: '3vw',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	thesisContent: {
		fontSize: 'var(--body-mm)',
		whiteSpace: 'pre-line',
		marginRight: 'auto',
	},
	markDownDiary: {
		maxWidth: '60%',
		maxHeight: '100%',
		margin: 'auto',
		marginLeft: '2vw',
		borderRadius: '6px',
		width: 'auto',
		height: 'auto',
		border: '2px solid #5d5d5d',
		animation: 'breathing 2s linear alternate-reverse infinite',
	},
	inlineLink: {
		color: 'var(--Overall-font-color)',
		textShadow: '1px 1px 3px #fff',
	},
};

export default BehindTheScenes;
