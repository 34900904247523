import { useState, useEffect } from 'react';

const useFetchStoryProjects = (url) => {
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
						'Content-Type': 'application/json', // If needed for your backend
					},
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const result = await response.json();
				console.log(result);
				setData(result);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError(error);
			}
		};

		fetchData();
	}, [url]);

	return { data, error };
};

export default useFetchStoryProjects;
