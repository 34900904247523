import React from 'react';

import normalFootageMedia from '../../5_assets/Thesis/normalFootage.webm';

const GameRules = () => {
	return (
		<div id="gameRules" style={styles.container}>
			<p style={styles.header}>What are the rules?📑</p>
			<div style={styles.thesisInnerContent}>
				<div style={styles.thesisContent}>
					<div style={styles.floating}>
						<p>Simple rules to survive, use simple controls to avoid the wave and not to hit it.</p>
						<ul style={styles.floating}>
							<li>The space between waves decreases.</li>
							<li>The amplitude of the wave is always changing, so there is no way to cheat.</li>
							<li>The game goes faster until double the initial speed.</li>
						</ul>
					</div>
				</div>
				<video style={styles.normalFootage} preload="auto" autoPlay muted loop>
					<source src={normalFootageMedia} type="video/webm" />
				</video>
			</div>
		</div>
	);
};

const styles = {
	container: {
		height: '100vh',
		position: 'relative',
		zIndex: 9,
	},
	header: {
		fontSize: 'var(--heading-6-xl)',
		paddingTop: '3vh',
		paddingBottom: '3vh',
		fontWeight: 400,
		textAlign: 'center',
	},
	thesisInnerContent: {
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '85vw',
		position: 'relative',
		display: 'flex',
		gap: '3vw',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	thesisContent: {
		fontSize: 'var(--body-mm)',
		whiteSpace: 'pre-line',
		marginRight: 'auto',
	},
	floating: {
		textAlign: 'left',
		marginLeft: '30px',
		marginTop: '5px',
	},
	normalFootage: {
		maxWidth: '19%',
		maxHeight: '100%',
		borderRadius: '10px',
		margin: 'auto',
		border: '2px solid #5d5d5d',
		animation: 'breathing 2s linear alternate-reverse infinite',
	},
};

export default GameRules;
