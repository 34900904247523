import React from 'react';
import latex_thesis from '../../5_assets/Thesis/LaTeX_Thesis.pdf';
import game_logo from '../../5_assets/Thesis/Gamelogo.webp';
import survive_line_zipped from '../../5_assets/Thesis/SurviveLine.zip';


const GameDownload = () => {
	return (
		<div id="gameDownload" style={styles.container}>
			<p style={styles.header}>Want to try it yourself?🕹</p>
			<div style={styles.thesisInnerContent}>
				<div style={styles.thesisContent}>
					<p>
						You can download "Survive Line" from the{' '}
						<a className="animated-link" href="https://github.com/Ahelsamahy/Survive-Line" target="_blank" rel="noreferrer">
							GitHub
						</a>{' '}
						repository.
					</p>
					<p>
						Check out the LaTeX thesis version{' '}
						<a className="animated-link" href={latex_thesis} target="_blank" rel="noreferrer">
							here
						</a>
						.
					</p>
				</div>
				<a href={survive_line_zipped}>
					<img style={styles.gameLogo} src={game_logo} alt="Game Logo" />
				</a>
			</div>
		</div>
	);
};
const styles = {
	container: {
		height: '100vh',
		position: 'relative',
		zIndex: 9,
	},
	header: {
		fontSize: 'var(--heading-6-xl)',
		paddingTop: '3vh',
		paddingBottom: '3vh',
		fontWeight: 400,
		textAlign: 'center',
	},
	thesisInnerContent: {
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '85vw',
		position: 'relative',
		display: 'flex',
		gap: '3vw',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	thesisContent: {
		fontSize: 'var(--body-mm)',
		whiteSpace: 'pre-line',
		marginRight: 'auto',
	},
	gameLogo: {
		maxWidth: '30vw',
		animationName: 'floating',
		animationDuration: '3s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'ease-in-out',
		margin: 'auto',
		marginLeft: '2vw',
		borderRadius: '6px',
		width: 'auto',
		height: 'auto',
		border: '2px solid #5d5d5d',
	},
};

export default GameDownload;
