import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useFetchEditStoryProjects = (url) => {
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		const fetchData = async () => {
			const token = localStorage.getItem('crudToken');

			if (!token) {
				navigate('/LoginPage'); // No token, redirect to login
				return;
			}
			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
						'Content-Type': 'application/json', // If needed for your backend
					},
				});

				if (response.status === 401) {
					// Token expired or unauthorized, remove token and redirect
					localStorage.removeItem('crudToken');
					navigate('/LoginPage');
				} else if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const result = await response.json();
				console.log(result);
				setData(result);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError(error);
			}
		};

		fetchData();
	}, [url, navigate]); // Include navigate as a dependency
	return { data, error };
};

export default useFetchEditStoryProjects;
