import React from 'react';
import { Link } from 'react-router-dom';

export const Header = () => {
	const styles = {
		indexHeader: {
			display: 'flex',
			alignItems: 'center', // vertically aligns items
			justifyContent: 'center', // centers items horizontally
			flexDirection: 'row',
			gap: '1rem',
			padding: '1rem',
		},

		headerBreak: {
			color: 'rgb(160, 160, 160)',
			width: '10%',
			borderWidth: '1px',
			margin: '0 auto 2rem auto',
		},
	};

	return (
		<>
			<header style={styles.indexHeader}>
				<Link to="/home" className="hover:text-[var(--basic-red-style)] transition-colors duration-300">
					Home
				</Link>
				<Link to="/allProjects" className="hover:text-[var(--basic-red-style)] transition-colors duration-300">
					Projects
				</Link>
				<a href="/Resume.pdf" target="_blank" className="hover:text-[var(--basic-red-style)] transition-colors duration-300">
					Resume
				</a>
			</header>
			<hr style={styles.headerBreak} />
		</>
	);
};
