import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import React, { useEffect, useState } from 'react';
import AcademicEvolution from '../2_components/LifeStoryPersonnal/AcademicEvolution.jsx';
import CulturalEngagement from '../2_components/LifeStoryPersonnal/CulturalEngagement.jsx';
import FromSpark from '../2_components/LifeStoryPersonnal/FromSpark.jsx';
import GlobalExposure from '../2_components/LifeStoryPersonnal/GlobalExposure.jsx';
import CurrentExpertise from '../2_components/LifeStorySkills/CurrentExpertise.jsx';
import RoboticInnovation from '../2_components/LifeStorySkills/RoboticInnovation.jsx';
import '../5_assets/styles/22_lifeStory.css';

export function LifeStory() {
	const [selectedSection, setSelectedSection] = useState(1);

	useEffect(() => {
		AOS.init(); // Initialize AOS
		AOS.refresh(); // Refresh AOS animations
	}, [selectedSection]);

	return (
		<div className="all-of-life ">
			<div className="section-titles">
				<span className={`animated-link ${selectedSection === 1 ? 'active' : ''}`} onClick={() => setSelectedSection(1)}>
					Professional Milestones
				</span>
				<span className={`animated-link ${selectedSection === 2 ? 'active' : ''}`} onClick={() => setSelectedSection(2)}>
					Personal Journey
				</span>
			</div>

			{selectedSection === 1 && (
				<div className="animate-fadeIn">
					<CurrentExpertise />
					<RoboticInnovation />
				</div>
			)}
			{selectedSection === 2 && (
				<div className="animate-fadeIn">
					<FromSpark />
					<AcademicEvolution />
					<GlobalExposure />
					<CulturalEngagement />
				</div>
			)}
		</div>
	);
}
