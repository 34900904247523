import React, { useEffect, useState } from 'react';
import AISolving from '../2_components/Thesis/AISolving';
import BehindTheScenes from '../2_components/Thesis/BehindTheScenes';
import CodeFeature from '../2_components/Thesis/CodeFeature';
import GameDownload from '../2_components/Thesis/GameDownload';
import GameRules from '../2_components/Thesis/GameRules';
import { changePageTitle } from '../4_utils/functions';
import SEO from '../4_utils/SEO.jsx';

export function ThesisBody() {
	useEffect(() => {
		changePageTitle('Survive Line | AhmedMahfouz.me');
	}, []);

	return (
		<>
			<SEO title="SurviveLine: Master the Waves with AI!" description="Dive into the mesmerizing world of Survive Line, a cutting-edge game powered by Pygame." name="Survive Line" type="AI-Driven Endurance Arcade Game" />
			<div id="Game">
				<GameRules />
				<CodeFeature />
				<AISolving />
				<BehindTheScenes />
				<GameDownload />
			</div>
		</>
	);
}
