import React from 'react';
import CreateAI from '../2_components/ThesisTimeline/CreateAI';
import DevelopTheGame from '../2_components/ThesisTimeline/DevelopTheGame';
import DisplayTheGame from '../2_components/ThesisTimeline/DisplayTheGame';
import Timeline from '../2_components/ThesisTimeline/Timeline';
import styles from '../5_assets/styles/ThesisTimeline.module.css';

export function ThesisTimeline() {
	return (
		<>
			<div className={styles.thesisTimeLine}>
				<Timeline />
				<DevelopTheGame />
				<DisplayTheGame />
				<CreateAI />
			</div>
		</>
	);
}
