import React from 'react';
import ScienceEventMedia from '../../5_assets/myImages/ScienceEvent.webp';

function AcademicEvolution() {
	return (
		<div className="life-story" style={styles.lifeStory} >
			<p className="firstSpark" style={styles.firstSpark}>
				<span className="numbering" style={styles.numbering}>
					2.
				</span>
				Academic Evolution
			</p>
			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<div className="lifeStory-content" style={styles.lifeStoryContent}>
					<p className="lifeStory-containerText">
						After a decade-long journey culminating in high school graduation, I was fortunate to secure a spot in the engineering program at{' '}
						<a href="https://www.deltauniv.edu.eg/" className="animated-link" target="_blank" rel="noreferrer">
							Delta University
						</a>
						. Here, I deepened my understanding of two subjects I've always been passionate about: physics and math. The intensity of the coursework was both a challenge and an enlightening experience.
						<br />
						<br />
					</p>
					<p className="lifeStory-containerText">Amid this demanding environment, I found a respite in a telecommunication specialization. One particularly memorable experience was a programming course where, in collaboration with a classmate, we developed a data management application for a library using C++. This project, though modest, became a pivotal moment, affirming my interest in programming and hinting at a potential future direction away from traditional engineering.</p>
				</div>

				<img className="lifeStoryPersonnalImage" src={ScienceEventMedia} alt="leaning on a wall in a science fair" title="What a pose" style={styles.lifeStoryPersonnalImage} />
			</div>
		</div>
	);
}
const styles = {
	lifeStory: {
		overflow: 'hidden',
		padding: '5vh 0px',
		width: '80vw',
		height: '100%',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	firstSpark: {
		fontSize: 'var(--heading-5-xl)',
		marginBottom: '1vh',
		paddingBottom: '1.5vh',
	},
	numbering: {
		color: 'var(--font-hover-color)',
	},
	lifeStoryInnerContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		position: 'relative',
		width: '100%',
		justifyContent: 'space-around',
	},
	lifeStoryContent: {
		fontSize: 'var(--body-mm)',
		fontWeight: 'normal',
		width: '65%',
	},
	lifeStoryPersonnalImage: {
		margin: 'auto',
		borderRadius: '10px',
		maxWidth: '30%',
		maxHeight: '100%',
	},
};
export default AcademicEvolution;
