import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

export function Footer() {
	return (
		<>
			<hr style={styles.footerBreak}></hr>
			<footer style={styles.footer}>
				<div>
					<p style={styles.footerCredits}>
						Designed and developed with <span style={styles.heart}>&#9829;</span> By Ahmed Mahfouz
					</p>
				</div>
				<ul style={styles.socialMediaConnection}>
					<li style={styles.socialMediaItem}>
						<a href="https://twitter.com/AhElsamahy" style={styles.socialMediaLink}>
							<FontAwesomeIcon icon={faTwitter} />
						</a>
					</li>
					<li style={styles.socialMediaItem}>
						<a href="https://github.com/Ahelsamahy" style={styles.socialMediaLink}>
							<FontAwesomeIcon icon={faGithub} />
						</a>
					</li>
					<li style={styles.socialMediaItem}>
						<a href="https://www.linkedin.com/in/ahmed-mahfouz-8005a017a/" style={styles.socialMediaLink}>
							<FontAwesomeIcon icon={faLinkedin} />
						</a>
					</li>
				</ul>
			</footer>
		</>
	);
}

// Inline styles defined at the end of the component
const styles = {
	footer: {
		bottom: '0',
		width: '100%',
		textAlign: 'center',
		padding: '1rem',
	},
	footerBreak: {
		color: 'rgb(160, 160, 160)',
		width: '15%',
		borderWidth: '1px',
		margin: '2rem auto 0 auto',
	},
	footerCredits: {
		marginBottom: '0.5rem',
	},
	heart: {
		color: '#7F7F7F',
	},
	socialMediaConnection: {
		listStyle: 'none',
		display: 'flex',
		justifyContent: 'center',
		padding: '0',
		gap: '1rem',
	},
	socialMediaLink: {
		color: 'var(--Overall-font-color)',
		textDecoration: 'none',
	},
};
