import React from 'react';
import { Link } from 'react-router-dom';
import ProjectCard from './projectCard.jsx';
import useFetchStoryProjects from '../3_hooks/useFetchStoryProjects.jsx';

export function Top3Projects() {
	const { data, error } = useFetchStoryProjects(`/api/projects/featured`);

	if (error) {
		return <div>Error: {error.message} {data}</div>;
	}

	return (
		<div style={styles.container}>
			<div style={styles.textCenter}>
				<p>Featured projects</p>
				<Link to="/allProjects" style={{ fontSize: '24px' }} className="animated-link">
					See all the others
				</Link>
			</div>
			<div style={styles.Top3ProjectsContainer} className="Top3ProjectsContainer">
				<ProjectCard data={data} showImage />
			</div>
		</div>
	);
}
const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	textCenter: {
		fontSize: 'var(--heading-6-xl)',
		fontWeight: 300,
		marginBottom: '2vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	Top3ProjectsContainer: {
		width: '80%',
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)', // Three columns per row
		gap: '2vw', // Gap between grid items
		alignItems: 'center',
		margin: '0 auto',
	},
};
