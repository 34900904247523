import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { changePageTitle } from '../4_utils/functions';
import SEO from '../4_utils/SEO.jsx';
import styleSheet from '../5_assets/styles/storyProject.module.css';
import useFetchStoryProjects from '../3_hooks/useFetchStoryProjects.jsx';
import LoadingIndicator from './LoadingIndicator.jsx';

const CustomLink = ({ href, children }) => {
	const isYouTubeLink = href.includes('https');
	const className = isYouTubeLink ? 'animated-link' : '';
	return (
		<a href={href} className={className} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};

const CustomImage = ({ node, ...props }) => {
	return (
		<span className={styleSheet.storyMediaContainer}>
			<img {...props} className={styleSheet.storyRoundedMedia} alt={props.title} />
			{props.title && <span className={styleSheet.storyMediaCaption}>{props.title}</span>}
		</span>
	);
};

const CustomVideo = ({ node, ...props }) => {
	const isVideo = props.src.endsWith('.webm') || props.src.endsWith('.mp4');
	if (isVideo) {
		return (
			<div className={styleSheet.storyMediaContainer}>
				<video controls className={styleSheet.storyRoundedMedia} style={{ maxWidth: '30%' }}>
					<source src={props.src} type="video/webm" />
					{props.title && <track kind="captions" label={props.title} />}
				</video>
				{props.title && <span className={styleSheet.storyMediaCaption}>{props.title}</span>}
			</div>
		);
	}
	return null;
};
export function StoryProject() {
	const [project, setProject] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { projectID } = useParams();
	const { data, error: fetchError } = useFetchStoryProjects(`/api/projects/${projectID}/story`);

	useEffect(() => {
		if (data && data.length > 0) {
			setProject(data[0]);
			setIsLoading(false);
		}
	}, [data]);

	if (isLoading) {
		return <LoadingIndicator />;
	}

	if (!project) {
		return <p>No project found</p>;
	}

	return (
		<>
			<SEO Title={project.MetaTitle} description={project.MetaDescription} name={project.Title} type={`${project.ProLanguage} Project`} />
			{changePageTitle(`${project.Title} | AhmedMahfouz.me`)}
			<div className="w-4/5 mx-auto flex flex-col animate-fadeIn">
				<h1 className="text-6xl font-bold mb-2">{project.Title}</h1>
				<p className="opacity-50 mb-4">Made with: {project.ProLanguage}</p>
				<div id="post" className="prose prose-invert list-disc list-inside max-w-none">
					<ReactMarkdown
						components={{
							a: CustomLink,
							img: (props) => {
								if (props.src.endsWith('.webm') || props.src.endsWith('.mp4')) {
									return <CustomVideo {...props} />;
								}
								return <CustomImage {...props} />;
							},
						}}>
						{project.Content}
					</ReactMarkdown>
				</div>
			</div>
		</>
	);
}
