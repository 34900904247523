import React from 'react';
import AirportMedia from '../../5_assets/myImages/Airport.webp';

function CulturalEngagement() {
	const styles = {
		lifeStory: {
			overflow: 'hidden',
			padding: '5vh 0px',
			width: '80vw',
			height: '100%',
			margin: '0 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		firstSpark: {
			fontSize: 'var(--heading-5-xl)',
			marginBottom: '1vh',
			paddingBottom: '1.5vh',
		},
		numbering: {
			color: 'var(--font-hover-color)',
		},
		lifeStoryInnerContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row-reverse',
			position: 'relative',
			width: '100%',
			justifyContent: 'space-around',
		},
		lifeStoryContent: {
			fontSize: 'var(--body-mm)',
			fontWeight: 'normal',
			width: '65%',
		},
		lifeStoryPersonnalImage: {
			margin: 'auto',
			borderRadius: '10px',
			maxWidth: '30%',
			maxHeight: '100%',
		},
	};

	return (
		<div className="life-story" style={styles.lifeStory}>
			<p className="firstSpark" style={styles.firstSpark}>
				<span style={styles.numbering}>4.</span>
				Cultural Engagement
			</p>
			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<img style={styles.lifeStoryPersonnalImage} src={AirportMedia} title="did they check in my luggage?" alt="looking out of Ferenc airport window in Budapest" />
				<div className="lifeStory-content" style={styles.lifeStoryContent}>
					<p>Daily interactions with diverse cultures broadened my perspective, underscoring the vastness and richness of the world around me. I firmly believe that every individual carries a unique story, and as a naturally sociable person, I sought to understand these narratives.</p>
					<br />
					<p>Proactively engaging with my peers, I endeavored to learn about their backgrounds, embracing the differences and finding common ground. These interactions were mutual; many were equally curious about my origins. Such exchanges enriched my understanding of the world and contributed significantly to my personal and social development.</p>
				</div>
			</div>
		</div>
	);
}

export default CulturalEngagement;
