import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import useFetchEditStoryProjects from '../3_hooks/useFetchEditStoryProjects';
import useHandleProject from '../3_hooks/useHandleProject';
const mdParser = new MarkdownIt();

const EditProject = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const isEditing = Boolean(id);

	const [project, setProject] = useState({
		Title: '',
		Description: '',
		ProLanguage: '',
		Content: '',
		MetaTitle: '',
		MetaDescription: '',
		PostImage: '',
		gitHubLink: '',
	});
	const [loading, setLoading] = useState(true);

	// Use your custom fetch hook for fetching the project data
	const { data, error: fetchError } = useFetchEditStoryProjects(isEditing ? `/api/projects/${id}` : null);

	// Use your custom hook for adding/updating the project
	const { handleProject, error: submitError } = useHandleProject();

	// Handle form submission for adding or editing a project
	const handleSubmit = async (e) => {
		e.preventDefault();
		const url = isEditing ? `/api/projects/${id}` : `/api/projects`;
		const method = isEditing ? 'PUT' : 'POST';

		const result = await handleProject(url, method, project);

		if (result) {
			console.log(`Project ${isEditing ? 'updated' : 'added'} successfully:`, result);
			// navigate('/viewprojects');
		}
	};
	// Populate the form when editing an existing project
	useEffect(() => {
		if (isEditing && data) {
			setProject(data[0]); // Assuming `data` is an array and the first element is the project
			setLoading(false);
		} else if (!isEditing) {
			setLoading(false); // If adding a new project, just set loading to false
		}
	}, [data, isEditing]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setProject((prevProject) => ({
			...prevProject,
			[name]: value,
		}));
	};

	const handleEditorChange = ({ text }) => {
		setProject((prevProject) => ({
			...prevProject,
			Content: text,
		}));
	};

	if (loading || !project) {
		return <p>Loading...</p>;
	}

	return (
		<div style={styles.container}>
			<h1 style={styles.heading}>{isEditing ? 'Edit Project' : 'Add New Project'}</h1>
			<form onSubmit={handleSubmit} style={styles.form}>
				<div style={styles.formGroup}>
					<label style={styles.label}>Title</label>
					<input type="text" name="Title" value={project.Title || ''} onChange={handleChange} required style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Description</label>
					<input type="text" name="Description" value={project.Description || ''} onChange={handleChange} required style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Programming language</label>
					<input type="text" name="ProLanguage" value={project.ProLanguage || ''} onChange={handleChange} style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Content</label>
					<MdEditor style={{ height: '500px' }} renderHTML={(text) => mdParser.render(text)} onChange={handleEditorChange} value={project.Content || ''} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Meta Title</label>
					<input type="text" name="MetaTitle" value={project.MetaTitle || ''} onChange={handleChange} style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Meta Description</label>
					<input type="text" name="MetaDescription" value={project.MetaDescription || ''} onChange={handleChange} style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>Post Image</label>
					<input type="text" name="PostImage" value={project.PostImage || ''} onChange={handleChange} style={styles.input} />
				</div>

				<div style={styles.formGroup}>
					<label style={styles.label}>GitHub Link</label>
					<input type="text" name="gitHubLink" value={project.gitHubLink || ''} onChange={handleChange} style={styles.input} />
				</div>

				<button type="submit" style={styles.button}>
					{isEditing ? 'Update Project' : 'Add Project'}
				</button>
			</form>
		</div>
	);
};

const styles = {
	container: {
		width: '80%',
		margin: '0 auto',
		padding: '40px',
		backgroundColor: '#f8f9fa',
		borderRadius: '8px',
		boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
	},
	heading: {
		fontSize: '3em',
		textAlign: 'center',
		color: '#333',
		marginBottom: '30px',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	formGroup: {
		marginBottom: '20px',
	},
	label: {
		display: 'block',
		marginBottom: '5px',
		fontWeight: 'bold',
		color: '#555',
	},
	input: {
		width: '100%',
		padding: '10px',
		fontSize: '16px',
		borderRadius: '4px',
		border: '1px solid #ced4da',
		transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
	},
	button: {
		padding: '12px 20px',
		fontSize: '16px',
		backgroundColor: '#007bff',
		color: 'white',
		border: 'none',
		borderRadius: '4px',
		cursor: 'pointer',
		transition: 'background-color 0.15s ease-in-out',
	},
};

export default EditProject;
