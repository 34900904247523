import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { Body } from './1_screens/Body.jsx';
import Error404 from './1_screens/Error404.jsx';
import { ThesisBody } from './1_screens/ThesisBody.jsx';
import { ThesisTimeline } from './1_screens/ThesisTimeline.jsx';
import { Header } from './2_components/_Header.jsx';
import { AllProjects } from './2_components/AllProjects.jsx';
import { StoryProject } from './2_components/StoryProject.jsx';
import { Footer } from './2_components/Z_Footer.jsx';
import ViewProjects from './1_screens/ViewProjects.jsx';
import EditProject from './1_screens/EditProject.jsx';
import LoginPage from './1_screens/LoginPage.jsx';
import PrivateRoute from './2_components/PrivateRoute.jsx';
import './5_assets/styles/app.css';
import './5_assets/styles/query.css'; // Style for media query. MUST BE THE LAST ONE

export function App() {
	return (
		<>
			<HelmetProvider>
				<div className="fade-in">
					<Header />
					<Routes>
						<Route path="/" element={<Body />} />
						<Route path="/home" element={<Body />} />
						<Route path="/thesis" element={<ThesisBody />} />
						<Route path="/thesistimeline" element={<ThesisTimeline />} />
						<Route path="/allProjects" element={<AllProjects />} />
						<Route path="/LoginPage" element={<LoginPage />} />

						{/* Protected Routes */}
						<Route
							path="/ViewProjects"
							element={
								<PrivateRoute>
									<ViewProjects />
								</PrivateRoute>
							}
						/>
						<Route
							path="/edit-project/:id"
							element={
								<PrivateRoute>
									<EditProject />
								</PrivateRoute>
							}
						/>
						<Route
							path="/Add-project"
							element={
								<PrivateRoute>
									<EditProject />
								</PrivateRoute>
							}
						/>

						<Route path="/storyProject/:projectID" element={<StoryProject />} />
						<Route path="*" element={<Error404 />} />
					</Routes>

					<Footer />
				</div>
			</HelmetProvider>
		</>
	);
}
