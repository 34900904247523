import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import styles from '../5_assets/LoginPage.module.css';

const LoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate(); // Initialize useNavigate
	const handleLogin = async (e) => {
		e.preventDefault();

		const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username, password }),
		});

		const data = await response.json();

		if (response.ok) {
			localStorage.setItem('crudToken', data.crudToken);
			console.log('Login successful:', data);
			navigate('/ViewProjects');
		} else {
			// Handle error
			setError(data.message || 'An error occurred');
		}
	};
	return (
		<div className={styles.loginContainer}>
			<div className={styles.loginBox}>
				<div className={styles.leftSection}></div>
				<div className={styles.rightSection}>
					<h2>Login</h2>
					<form onSubmit={handleLogin}>
						<label htmlFor="username">Username</label>
						<input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
						<label htmlFor="password">Password</label>
						<input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
						{error && <p style={{ color: 'red' }}>{error}</p>}
						<button type="submit">Login</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
