import React from 'react';
import SmoothieMedia from '../../5_assets/myImages/Smoothie.webp';

function CurrentExpertise() {
	const styles = {
		lifeStory: {
			overflow: 'hidden',
			padding: '5vh 0px',
			width: '80vw',
			height: '100%',
			margin: '0 auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		firstSpark: {
			fontSize: 'var(--heading-5-xl)',
			marginBottom: '1vh',
			paddingBottom: '1.5vh',
		},
		numbering: {
			color: 'var(--font-hover-color)',
		},
		lifeStoryInnerContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'right',
			position: 'relative',
			width: '100%',
			justifyContent: 'space-around',
		},
		lifeStoryContent: {
			fontSize: 'var(--body-mm)',
			fontWeight: 'normal',
			width: '65%',
		},
		learntTech: {
			opacity: 0.8,
			display: 'grid',
			gridTemplateColumns: 'repeat(2, minmax(140px, 10vw))',
			padding: '0px',
			margin: '0px 30px 0px 5vw',
			listStyle: 'none',
			fontSize: 'var(--body-1-xs)',
		},
		lifeStoryPersonnalImage: {
			margin: 'auto',
			borderRadius: '10px',
			maxWidth: '30%',
			maxHeight: '100%',
		},
	};

	return (
		<div className="life-story aos-init aos-animate" style={styles.lifeStory} >
			<p className="firstSpark" style={styles.firstSpark}>
				<span className="numbering" style={styles.numbering}>
					1.
				</span>
				Current Endeavors &amp; Expertise
			</p>
			<div className="lifeStory-innerContent" style={styles.lifeStoryInnerContent}>
				<div className="lifeStory-content" style={styles.lifeStoryContent}>
					<p className="lifeStory-containerText">
						Transitioning from academic pursuits, I continually explore and experiment, with many of{' '}
						<a className="animated-link" href="https://github.com/Ahelsamahy?tab=repositories" target="_blank" rel="noreferrer">
							my projects
						</a>{' '}
						reflecting personal interests and hobbies, a testament to{' '}
						<span data-toggle="tooltip" data-placement="top" title="I get the feeling of proud dad when I see them">
							my passion
						</span>{' '}
						for technology.
					</p>
					<p className="lifeStory-containerText">As I approached the conclusion of my academic journey, my focus was driven towards artificial intelligence, particularly leveraging Python. My current role as an AI developer is not merely a career choice; it's a manifestation of my genuine interest, independent of academic obligations.</p>
            <p className="lifeStory-containerText">
              In my free time, I've dedicated significant time to Python automation and{' '}
              <span data-toggle="tooltip" data-placement="top" title="This site is an example">
                web development
              </span>{' '}
              , ensuring that I stay up-to-date with the latest technological advancements. My commitment to excellence is mirrored in my{' '}
              <span data-toggle="tooltip" data-placement="top" title="I graduated the first on class">
                academic performance
              </span>
              , consistently maintaining a commendable GPA.
            </p>
					<p className="lifeStory-containerText">In terms of technical proficiencies, I'm well-versed in:</p>
					<ul className="learntTech" style={styles.learntTech}>
						<li>Python</li>
						<li>JavaScript</li>
						<li>ReactJS</li>
						<li>ExpressJS</li>
						<li>SQL</li>
						<li>CSS &amp; HTML</li>
						<li>Java</li>
						<li>C#</li>
						<li>C++</li>
						<li>PHP</li>
					</ul>
				</div>
				<img style={styles.lifeStoryPersonnalImage} className="lifeStoryPersonnalImage" src={SmoothieMedia} title="maybe it is spring" alt="Drinking smoothie with a leafy shirt" />
			</div>
		</div>
	);
}

export default CurrentExpertise;
