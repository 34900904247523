import React from 'react';

export const ContactForm = () => {
	return (
		<div style={styles.container}>
			<p style={styles.contactMeHeader}>Get in Touch</p>
			<p style={styles.contactMeDescription}>
				Whether you have a question, a potential collaboration in mind, or just want to chat, you can reach out on my{' '}
				<a className="animated-link" href="mailto:ahmelsamahy@gmail.com" target="_blank" rel="noreferrer">
					personal email
				</a>
				. I will try to get back to you as soon as possible.
			</p>
		</div>
	);
};
const styles = {
	container: {
		height: '70vh',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
	},
	contactMeHeader: {
		fontSize: 'var(--heading-6-xl)',
		fontWeight: 300,
		marginBottom: 0,
	},
	contactMeDescription: {
		width: '50%',
		display: 'inline-block',
		whiteSpace: 'pre-line',
	},
};
