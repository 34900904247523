import React from 'react';
import ProjectCard from './projectCard.jsx';
import useFetchStoryProjects from '../3_hooks/useFetchStoryProjects';

export function AllProjects() {
	const { data, error } = useFetchStoryProjects(`/api/projects/`);

	// Conditionally apply styles based on screen size
	const styles = {
		container: {
			width: '80%',
			columnWidth: '300px',
			justifyContent: 'center',
			margin: '0 auto',
		},
	};

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<div style={styles.container} className="ForAllProjectsContainer">
			<ProjectCard data={data} showImage={false}  />
		</div>
	);
}
