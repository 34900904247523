import CodeBlock from '../CodeBlock';
import styles from '../../5_assets/styles/ThesisTimeline.module.css';

import React from 'react';

function DevelopTheGame() {
	return (
		<details className={styles.thesisTimeLine}>
    <summary style={{ fontSize: '2em', fontWeight: 'bold' }}>Develop the game</summary>
				<summary />
				<p>
					&nbsp; The files layout of the game will be an <code>AI.py</code> file in the root folder, then subfolder named <code>SurviveLine</code> with 3 files in it <code>ballFunc.py</code>, <code>waveFunc.py</code> and <code>game.py</code>. To make it easy to make instance of the game, will create a file named <code>__init__.py</code> that will only have one line it it <code>from .game import Game</code> that means we will have a <code>Class Game():</code> in the <code>game.py</code> and it is used to call the <code>game</code> function as a library in the <code>AI.py</code> file (as it is in another folder) and make instance from it. Every major component will have its own class in file to refer later. &nbsp;
				</p>
				<h3>Wave functionality</h3>
				<p>To get the base function of wave there would be a lot of functions to cover like:</p>
				<CodeBlock
					language="Python"
					code={`  def draw(self, Display):
          #increase the FPS of game
      def changeSpeed(self):
          #change the wave amplitude and increase the wave gap
      def changeWave(self):
          #generate a new point on Y axis
      def generateWave(self):
          #add point to the list of points
      def addPoint(self, index, point):
          #check if there is a gap 
      def checkGap(self):
          #function to fill it
      def fillGap(self, gap, gapDirection):
          #reset all the self. variable that are made in __init__ class 
      def reset(self):`}
				/>

				<p>
					most of them are self explanatory, but the ones that need more dive into details are the <code>generateWave</code>, <code>checkGap</code>
					and <code>fillGap</code>
				</p>
				<h4>Generate Wave</h4>
				<p>
					The starting point of the game, in the <code>waveFunc.py</code> to make a main class <code>Class Wave():</code> with an equation that can generate a wave and at the same time I can change in the variables of the wave to make it harder for the player. These variable are are wave amplitude or wave frequency.
				</p>

				<p>With all of this in calculation.It means that I can make the game harder by making the behaviour unexpected for the next move, also to go extra step. There will be a decrease in the gap between the two waves to limit the player's movement.</p>
				<p>
					There are some variables that have the <code>self.</code> before, that are defined as:
				</p>
				<CodeBlock
					language="Python"
					code={`  def __init__(self, wDisplay, hDisplay):
        self.WDisplay = wDisplay
        self.HDisplay = hDisplay
        self.ScoreCount = 0  
        self.waveFreq = 1  
        self.WaveGap = 0
        self.GameSpeed = 2  # to increment the difference in time to speed the FPS
        self.FPS = 60
        self.WaveAmplitude = 50
        self.PointsI = 0  # index to loop inside the points list
        self.PointsList = [0]*800`}
				/>
				<p>These are the variables that are only (and not specifically) linked to the wave functions, and this is where an important functionality of OOP comes in. Encapsulation is the OOP functionality which means to get all the related data to a class (which is wave class at this point), if it is needed in other classes, then an instance of class wave can be made then the new variable can be used from it.</p>
				<p>
					The equation in figure #### is going to store the X axis coordinates in a list called <code>PointsList</code> for the sake of adding points to it once they are generated and show them on screen one by one as if it is loading, because if there isn't a list, then the wave would be a steady visual sine wave (without changing amplitude or frequency yet), this part of code is placed in <code>def generateWave(self)</code> function.
				</p>
				<p>There have to be more condition to make the points be generated without disorder, like one point won't be in the other half of screen, which means that when the 350px is added to it, it will be out of the borders of the display.</p>
				<h4>Check gap</h4>
				<p>
					After generating a point, with the change in amplitude, the next point that is added to list of points doesn't have a difference of only 1px with the one before it, so that means that there will be a different line segments in the list and a gap between the old point and the new one. To overcome this, after a point is generated, there is a <code>for loop</code> that checks if there is only one pixel gap between it (the new point) and the point before it, either it is minus or negative as the gap can be to left side or right side
				</p>
				<h4>Fill Gap</h4>
				<p>If there is one part which took the most in the developing, I would say it is this part, because there were different approaches to solve the problem. First one is either to move the point on y-axis by the gap then make a straight line from the old line segment to it, and the second one was to get the point just to be minus on the x-axis then be linked to it. The first option was better for the sake of visibility and not effecting the next point respectively. There were lots of ways (or you can say conditions) that needs to be covered in the point list, for example what if the gap is at the end of list? will throw &quot;out of index error&quot; when trying to shift the new point by the amount of gap. One way to cover this is by removing amount of points from the start of the list, then add the same amount at the end where you need it.</p>
				<p>
					Say that the gap is over the limit of list (800Px), dealing with it before was just to make the gap limited to the end of list, so if the point is at index 797 and the gap is 10 (that means there will be an out of index at extra index 6) so it was just to make it limited to <code>gap = DISPLAY_H - POINTS_I - 1</code> but the problem is that it wouldn't work on high scale when the amplitude gets higher. To deal with it is to remove the over-points in gap from the beginning of the list and add empty points of the same amount at the end then make the index go back to the new index, (back to the same example). It will remove 6 points from the beginning of list then add empty 6 points to the end, and shift the index to 6 points in the back so it stays with the new point.
				</p>
				<CodeBlock
					language="Python"
					code={`  if self.PointsI + (gap) &gt;= self.HDisplay-1:
        untilEnd = self.HDisplay-self.PointsI
        toAddFromStart = abs(gap-untilEnd)
        del self.PointsList[:toAddFromStart]
        toAdd = [0]*toAddFromStart
        self.PointsList.extend(toAdd)
        self.PointsI -= toAddFromStart
        gap -= 1`}
				/>
				<p>with every line here looking weirdly by itself, you would need some explanation:</p>
				<ul>
					<li>Line 2: calculates the difference between the ending point of list and the starting point of gap.</li>
					<li>Line 3: get the difference in gap and the point.</li>
					<li>Line 4: delete the amount of point from the beginning of list.</li>
					<li>Line 5: create empty list with the amount of delete points from beginning of list.</li>
				</ul>
				<p>Now with the condition being fulfilled, it comes to fill the gap itself. There would be two options, if the gap is negative or positive, but I will discuss the negative gap and the other one have the same implementation with the difference being the sign.</p>
				<CodeBlock
					language="Python"
					code={`  if (gapDirection):
        # to move the point according to gap
        self.PointsList[self.PointsI + gap] = self.PointsList[self.PointsI]
        self.PointsList[self.PointsI] = 0
          #the step is different for gap direction, as it would be -1 or +1
        for x in range(self.PointsList[self.PointsI-1], self.PointsList[self.PointsI+gap]-1, (gap//gap)):
          self.PointsList[insideY] = x+1
          if insideY &lt; 799:
            insideY += 1
                `}
				/>
				<p>First it moves the first point in the new line segment by the amount of gap, then resets the old value of it to zero (as it will be part in the straight line). Secondly is a for loop to fill the points incrementally starting from the last point in the old line segment to the new point.</p>
				<h4 id="fillGapOnXAxis">Second way to fill the gap</h4>
				<p>Fill the gap was basically working on the base of shifting the point on Y-axis, but there might be another approach to tackle this ( the second way I talked about in fixing the problem of gap).</p>
				<p>Thinking that it will take more effort to move the point in new line segment in the position that corresponds to the gap, then make a line between the old line segment and the new one. That is a lot to think about, there can be a different way. What if we change the point on x-axis? just to make it close to the old one, I know it is a bit of cheating, but as long as it works, then it is good.</p>
				<p>The idea is that, if I can calculate the gap (which I already know) then decrease the new point by the amount of gap + 1 (if it is a positive gap) and will be -1 if it is a negative gap, you may ask, &quot;why didn't you use absolute value for amount of gap as left is the same as right?&quot; because then this would mean that the wave would increment in one way which depends if it is +1 or -1.</p>
				<p>
					The newly implemented function is called <code>def shiftOnXAxis(self, newPoint)</code> in <code>waveFunc.py</code>
				</p>
				<h3>Ball functionality</h3>
				<p>The main focus when working on the ball was to make it as simple as it can be, so a new instance can be done from it without the need to store a self-genome variable, and every genome would have its own variables that can be changed with a new instance made.</p>
				<h4>Draw ball</h4>
				<p>
					As the game is based on a <strong>ball</strong> that survives a line, then I need to display a ball and not a circle (google the difference). There isn't a function to draw a filled ball in one line, so I have to draw an empty circle then fill it. The function <code>pygame.gfxdraw.aacircle</code> will draw an anti-aliased circle and <code>pygame.gfxdraw.filled_circle</code> draw a filled circle inside of it, then draw a fake rectangle around them with <code>pygame.Rect</code> that will deal with the collision (will discuss it in the display game section).
				</p>
				<h4>Generate particles</h4>
				<p>This part is little on logic than the other because it was made for the visuality of the game, no output coming out of it to make the game faster or improve something, but it would add a little bit of a characteristic to the game and the vision I have for it.</p>
				<p>The particles are made to be in the position of the ball and generate as a way to look like a combustion engine steam coming out ot it, so there are three things to notice here.</p>
				<ul>
					<li>Location: where the particles will start and their ending point.</li>
					<li>Velocity: the amount of particles that will be generated in a second.</li>
					<li>Time: how long they will last on the screen.</li>
				</ul>
				<p>With this in consideration, we can start writing a function for it </p>
				<CodeBlock
					language="Python"
					code={` def generateParticles(self):
        Loc =[self.ballCordX, self.ballCordY] 
        Vel = [random.randint(0, 20) / 10 - 1, -3]
        Timer = random.randint(4, 6)
        self.Particles.append([Loc, Vel, Timer])
        for particle in self.Particles:
            particle[0][0] -= particle[1][0]
            particle[0][1] -= particle[1][1]
            particle[2] -= 0.1
    
            pygame.draw.circle(self.GameDisplay, (255, 255, 255), [int(
                particle[0][0]), int(particle[0][1])], int(particle[2]))
            if particle[2] &lt;= 0:
                self.Particles.remove(particle)`}
				/>
				<p>
					In the <code>Vel</code>variable deceleration part, it makes sure that the value we would get, would be a random number between -1 to 1. The <code>Timer</code> to give chaos to the particles so not all of them are released at the same time.
				</p>
				<p>
					The code would add to the list of particles a new particle with these random starting values, then the <code>for loop</code> process each value on its own.
				</p>
				<ul>
					<li>Line 7: it process the position on X-axis to the velocity also on the X-axis, same would happen to the Y-coordinates.</li>
					<li>
						<code>particles[2]</code> is to reduce the particle radius by 0.1 in every frame (which is every loop then).
					</li>
					<li>If condition at the end to remove the particle from the list so it wouldn't take much of space with more runtime.</li>
				</ul>
				<p>
					This function is possible thanks to &nbsp;
					<a className="animated-link" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=F69-t33e8tk">
						Particles - Pygame Tutorial - YouTube
					</a>
				</p>
			</details>
	);
}

export default DevelopTheGame;
