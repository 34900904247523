import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { storyCoverPath } from '../4_utils/functions.jsx';
import styles from '../5_assets/styles/cards.module.css';

function ProjectCard({ data, showImage }) {
	// Function to render the media (image or video)
	const renderMedia = (postImage) => {
		if (postImage.substr(postImage.indexOf('.')) === '.webm') {
			return (
				<video preload="auto" autoPlay muted loop>
					<source src={storyCoverPath(postImage)} type="video/webm" />
				</video>
			);
		}
		return <img src={storyCoverPath(postImage)} alt="project's cover" />;
	};

	// Function to render the buttons (Story and GitHub)
	const renderButtons = (project, i) => {
		return (
			<ul className={styles.projectsCardButton}>
				{project.Content && (
					<li key={`content-${i}`}>
						{project.Content.length > 2 && project.Content.length < 50 ? (
							<Link to={project.Content} className={`hover:text-[var(--basic-red-style)] transition-colors duration-300 ${styles.toStoryButton}`}>
								Story
							</Link>
						) : (
							<Link to={`/StoryProject/${project.ProjectID}`} className={styles.toStoryButton}>
								Story
							</Link>
						)}
					</li>
				)}

				{project.gitHubLink && (
					<li key={`github-${i}`}>
						<a href={project.gitHubLink} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faGithub} className={styles.toGithubButton} />
						</a>
					</li>
				)}
			</ul>
		);
	};

	return (
		<>
			{data.map((project, i) => (
				<React.Fragment key={i}>
					<div key={i} className={styles.card} style={{ marginBottom: showImage ? '0' : '2vh' }}>
						{showImage && <div className={styles.image}>{renderMedia(project.PostImage)}</div>}
						<div className={styles.cardInner}>
							<div className={styles.languageColor} style={{ backgroundColor: project.color }}></div>
							<p className={styles.CardTitle}>{project.Title}</p>
							<p className={styles.ProLanguageText}>{project.ProLanguage}</p>
							<p className={styles.PostDescription}>{project.Description}</p>
							{renderButtons(project, i)}
						</div>
					</div>
				</React.Fragment>
			))}
		</>
	);
}

export default ProjectCard;
