import React from 'react';
import myImage from '../5_assets/myImages/1_me.webp';

const IntroSection = () => {
	return (
		<div style={styles.container}>
			<div style={styles.aboutMeSection}>
				<p style={styles.welcomeMessage}>Hi, my name is</p>
				<p style={styles.fullName}>Ahmed Mahfouz.</p>
				<p style={styles.introMessage}>I will take you on a tour of my life as a software developer</p>
			</div>
			<img src={myImage} alt="Updated LinkedIn" className="profile-picture" style={styles.profilePicture} />
		</div>
	);
};

const styles = {
	container: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		margin: '0 auto',
		padding: '2vh auto',
		width: '90%',
	},
	aboutMeSection: {
		marginTop: '10%',
		display: 'flex',
		flexDirection: 'column',
		gap: '2vh',
		fontWeight: '200',
	},
	welcomeMessage: {
		fontSize: 'large',
		marginBottom: '0rem',
	},
	fullName: {
		fontSize: 'var(--heading-6-xl)',
		lineHeight: '70px',
		paddingLeft: '20px',
	},
	introMessage: {
		opacity: 0.7,
		paddingLeft: '20px',
		marginTop: '-1rem',
	},
	profilePicture: {
		objectFit: 'cover',
		width: '40vw', // Adjust the width of the image
		height: '88vh',
		borderRadius: '10px',
		objectPosition: '100% 0',
		opacity: 0.5,
	},
};

export default IntroSection;
