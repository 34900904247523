import { thesisMediaPath } from '../../4_utils/functions';
import styles from '../../5_assets/styles/ThesisTimeline.module.css';
import CodeBlock from '../CodeBlock';
import React from 'react';

function DisplayTheGame() {
	return (
		<details className={styles.thesisTimeLine}>
    <summary style={{ fontSize: '2em', fontWeight: 'bold' }}>Display the game</summary>
				<p>
					Getting all of the parts coming together is based on logic, as there are lots of changes in it with variables in each game component <code>waveFunc</code> and <code>ballFunc</code> but the basics are made in the game. Now about displaying them from the <code>game.py</code> file that will deal as an instance of all game component, like the string that will keep all of them tide up.
				</p>
				<h3>Draw</h3>
				<p>
					There are basic things in the game that a user would need to always see during the runtime of it, like the ball to know the position of it and the wave so the ball won't hit it, maybe the particles to add some visuality to the game and the score on the top. Most of these functions are already made in the components class but needs to be called here, in the <code>game.py</code> file.
				</p>

				<h4>Update label</h4>
				<p>In order to type anything on the screen in PyGame, you need to:</p>
				<ol>
					<li>Instantiate first the font you will use, how big it will be.</li>
					<li>Decide what to write then make a position for it on the screen.</li>
					<li>Blit (show on screen).</li>
				</ol>
				<p>It can be thought of as a harder way just to write a simple text, but it gives more freedom to customize the text before writing it and it's also CPU consuming wise when it comes to graphics options that you might not need, like anti aliasing.</p>

				<h4 id="display-score">Display score</h4>
				<p>
					There is a need for a counter in the game, to be incremented each time the main <code>While</code> loop in the game makes a new round, so it would work as a way to measure the score of the game (also fitness for AI), this <code>ScoreCount</code> will be the way to measure it, but as every loop, that is +60 (FPS of game) points in every second. I think it might be a little bit over, so I will divide it by 200 and place it on the middle top part of the screen, now first part of the game requirement is over, with displaying score.
				</p>
				<h4>Display AI number</h4>
				<p>As a way to keep record of the training sessions of AI is to find the generation number and genome number, but if you don't understand these expressions, it is ok, I don't also, but I will read about them once I reach the AI part and tell you more there. During the learning period, I won't be staring in my screen the whole time, so there have to be a way I can know which genome did better and the time for it. The log will be in CMD after every genome and will use OBS to record the game window only, so I return to the video at anytime.</p>
				<h3 id="collision">Collision</h3>
				<p>Basics of collision is when there is an object that overlaps another object and they should't. To implement this on the game, a collision would be triggered if the ball overlaps any point of the wave points that corresponds to the same x-axis points with it.</p>
				<p>
					In the Ball component class there is a <code>ballRect</code> functions, that returns an invisible rectangle that surrounds the ball with lots of important functionality in it. Basically I can access all the coordinates of every edge in this rectangle that would help in the collision, and this will be the way of detection for the ball. As for the collision function working mechanism, it loops through every point in the wave with the range of the ball, in this case it is calculated by the full height of screen (800 px) minus the bottom of the ball (238 x-cord for it) \(800-562 = 238\) this is the starting range. Ending range will be the the top of ball, \(800-538 = 262\), for the right side of ball, if the x value for it is over or equal the x-axis of the points list with the same axis -50 - the wave gap then it will be collision. On left side, if the x-axis of left side of ball is less or equal the x-axis of the points list with the same axis - 350 - the wave gap then it will
					be collision.
				</p>
				<CodeBlock
					language="Python"
					code={`#                              238          ,             262
    for YCord in range(self.HDisplay-ball.bottom, self.HDisplay-ball.top):
        if (Wave.PointsList[YCord] != 0) and (ball.right &gt;= Wave.PointsList[YCord]-50-Wave.WaveGap):
            return runLoop == False
    
        if (Wave.PointsList[YCord] != 0) and (ball.left &lt;= Wave.PointsList[YCord]-350+Wave.WaveGap):
            return runLoop == False`}
				/>
				<p>You might ask, why make a rectangle be responsible for the detection of a ball object. The problem is limitation in the library itself to choose object that you want to have detection with, even pixel wise, it is 5 pixels on the top for each side that decreases way down until the middle of ball, so it isn't that much to think about $$figure for the ball and ball rect</p>
				<h3>Move ball</h3>
				<p>Remember what I said about OOP and every class should make what is limited to? that is when it comes in handy, as here I can set the limitation I want for the ball movement, and not in the ball class.</p>
				<p>
					There will be buttons assigned to each movement in the class to get the ball around the x-axis only. If the function gets &quot;right&quot; as a parameter, then it will call the <code>moveball</code> function in <code>ballFunc.py</code> file to add to the x-axis of the ball, the amount of steps that is 5px, also the same with left side. Limitation is not to get the ball out side the borders of screen and an extra half of the radius of ball because it wasn't looking good having the whole ball stuck to the screen side as a limit.
				</p>
				<p>You might ask me, &quot;why make the parameter for function a string and not a Boolean variable as there are two types of movement only?&quot;, I'm not going to lie, I had it during the whole game to be as a boolean variable, until the implementation of AI, I changed it to string. Why? because in first place I thought the neural network would have 2 outputs (left and right movement) but there would be a third one, as not moving, or centre in other words, to make the ball steady during the training and not wiggling around.</p>
				<h3 id="count-distance">Count distance</h3>
				<p>As of the early implementation of the AI, the input was (the order from left to right):</p>
				<ul>
					<li>Distance between the centre of ball to the point in wave with the same x cord both left and right.</li>
					<li>Ball x cord.</li>
				</ul>
				<p>But the problem is the collision covers the whole ballRect (that is 24 pixel) and the NN can only have input of the centre, to solve it you would need to pass a list of the distance between the points on ballRect and the wave on the side, here is a big dive into it.</p>
				<p>To work on the right distance, there is ballRect that covers the whole ball and responsible for the collision detection, and there are points with the corresponding x axis on the wave. If any point on the ballRect overlap one of the points (with same x-axis) on the wave, the collison will be triggered and this genome will be over. To think more into details, I need to check first with the point on the bottom right side of the ballRect with the one same as x axis on the wave, then move one point up on wave and calculate it (using Pythagoras). When the points on the wave in the vision for the ball are over, then move one pixel up the ballRect and repeat the same on the wave.</p>
				<p>
					<img className={styles.timeLineMedia} src={thesisMediaPath('vision 576 lines.png')} alt="ball vision"></img>
				</p>
				<p style={{ textAlign: 'center', fontSize: '0.75rem' }}>keep in mind that the starting point is in the middle of the ball in the image, but it is just a simple way to say that there is a vision</p>
				<CodeBlock
					language="Python"
					code={`def countDistance(self):
        """
        make a list for distance between the bottom edge of ballRect to the corresponding point on the wave 
        with the same x-axis, then one step up on wave and repeat, and make another loop to do the same with the left side
        """
        ball = self.Ball.ballRect()
        Wave = self.Wave
        rightList = []
        leftList = []
        self.showedLines.clear()
        # the starting point here is the bottom of ball and the end is the top + 20 px for prediction
        # increased the step size because there was lag for the whole process to be handled
        for YCord in range(self.HDisplay-ball.bottom, self.HDisplay-ball.top+20, 5):
            for YBall in range(0, ball.width, 5):
                dxR = pow(Wave.PointsList[YCord] - 50 - Wave.WaveGap - ball.bottomright[0] + YBall, 2)
                dxL = pow(Wave.PointsList[YCord] - 350 + Wave.WaveGap - ball.bottomleft[0] + YBall, 2)
                dy = pow(abs((800-YCord) - ball.bottomright[1]+YBall), 2)
    
                rightList.append(int(math.sqrt(dxR+dy)))
                leftList.append(int(math.sqrt(dxL+dy)))
                self.showedLines.append(YCord)
        return rightList, leftList`}
				/>
				<p>&nbsp;</p>
				<p>Sounds good, but the problem is the two for loops were CPU consuming, imagine there are 24 pixel then repeat it again for the wave ones (a two inner for loops) \(24^2 = 576\) and another one for the left side \(576*2 = 1152\), and to process them again on the AI input , that is \(1152*2 = 2304\) times, all is made in one second, not to add the particles that are being made behind the ball, all of this was a lot. To make a little better, I reduced the step size to be 5 for each loop, made it to 45 in each list and got the inserted value to be as integer.</p>
				<figure>
					<table>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>576 lines on each side</th>
								<th style={{ textAlign: 'center' }}>45 on each side</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ textAlign: 'center' }}>
									<img className={styles.timeLineMedia} alt="576 vision lines" src={thesisMediaPath('vision 576 lines.png')} referrerPolicy="no-referrer"></img>
								</td>
								<td style={{ textAlign: 'center' }}>
									<img className={styles.timeLineMedia} alt="45 vision lines" src={thesisMediaPath('vision 45 lines.png')} referrerPolicy="no-referrer"></img>
								</td>
							</tr>
						</tbody>
					</table>
				</figure>
				<p style={{ textAlign: 'center', fontSize: '13px' }}>the amount of new lines isn't fixed and will be changed later</p>
				<p>You might ask &quot;will this reduce the vision of the AI ?&quot;, quite frankly, I think if it could handle itself with only one point in the past, then it can do better with 45 line on each side. It even gave more space to add extra point, so it can see the future now (an extra 20 points on wave).</p>
				<h3>Show Vision</h3>
				<p>
					The speed of learning during the AI would be faster than a human can play a game, and due to the amount of loops that are made every time in the main loop <code>while</code>, the calculation time is a lot to take, so to make the things smoother, there are vision lines of the ball that would help in viewing what the ball is actually seeing (or detecting) on the wave, but there would be a control for it in the <code>draw()</code> method so it doesn't slow the process of game by pressing the <code>v key</code> it would switch it on or off. &nbsp;
				</p>
				<h3>Loop</h3>
				<p>
					There are some functions that need to be always working during the game. Changing the wave amplitude, increase the <code>ScoreCount</code> by one and change the game speed as in FPS. <code>ScoreCount</code> is just a holder for them so it doesn't get missy in the AI part .
				</p>
				<h3>Reset</h3>
				<p>
					Calls the reset function in components class in the <code>Wave</code> and <code>Ball</code> and is triggered once the collision happens.
				</p>
			</details>
	);
}

export default DisplayTheGame;
