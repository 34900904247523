import React, { useEffect, useRef } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.min.css';

const CodeBlock = ({ language, code }) => {
	const codeRef = useRef(null);

	useEffect(() => {
		if (codeRef.current) {
			hljs.highlightBlock(codeRef.current);
		}
	}, [code]);

	return (
		<pre className={"language-"+language}>
			<code ref={codeRef} className={language}>
				{code}
			</code>
		</pre>
	);
};

export default CodeBlock;
